import { useState, useEffect } from 'react';

import { useIsFirstRender } from '../../_utils/hooks/use-is-first-render';
import { isPostalCodeLocal } from '../../_utils/validations';

export function usePostalCode(initialValue, required) {
  const isFirstRender = useIsFirstRender();
  const [postalCode, setPostalCode] = useState(initialValue || '');
  const postalCodeBlank = !postalCode.trim();
  const postalCodeInitallyBlank = isFirstRender && postalCodeBlank;
  const notRequiredAndBlank = !required && postalCodeBlank;
  const postalCodeValid = postalCodeInitallyBlank || notRequiredAndBlank ? true : isPostalCodeLocal(postalCode);
  useEffect(() => {
    if (!isFirstRender) {
      setPostalCode(initialValue);
    }
  }, [initialValue]);

  return {
    postalCode,
    setPostalCode,
    postalCodeValid
  }
}
