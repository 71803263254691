import Store from '../../_utils/tools/store';

const US_ZIP_CODE_PROPS = {
  inputMode: 'numeric',
  pattern: '[0-9]{5}',
  maxLength: 5,
};

const UK_POSTCODE_PROPS = {
  maxLength: 8,
};

const IE_POSTCODE_PROPS = {
  maxLength: 9,
};

export function countrySpecificProps() {
  if (Store.isUnitedStates()) {
    return US_ZIP_CODE_PROPS;
  }
  if (Store.isIreland()) {
    return IE_POSTCODE_PROPS;
  }
  return UK_POSTCODE_PROPS;
}
