import React from 'react';
import PropTypes from 'prop-types';

import translate from '../../_utils/tools/translate';

export default function InvalidPostalCodeWarning({ id }) {
  return (
    <div className='invalid-postal-code-warning' id={id} data-test-id='invalid-zip-code-warning' role='alert'>
      <p className='mb-1'>
        <small className='text-danger'>
          Please enter a valid {translate('zip code')}.
        </small>
      </p>
    </div>
  );
}

InvalidPostalCodeWarning.propTypes = {
  id: PropTypes.string,
}

InvalidPostalCodeWarning.defaultProps = {
  id: 'invalid-zip-code-warning',
}
