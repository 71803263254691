import React, { useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import InvalidPostalCodeWarning from './invalid-postal-code-warning';
import { countrySpecificProps } from './country-specific-props';
import { usePostalCode } from './use-postal-code';
import translate from '../../_utils/tools/translate';

let ids = 0;
function uniqueId() {
  return `postal-code-input-${ids++}`; // eslint-disable-line no-plusplus
}

function PostalCodeInput({
  name,
  id: idProp,
  initialValue,
  showPlaceholder,
  inputClassName,
  labelClassName,
  wrapperClassName,
  required,
  autoFocus,
  onInputRef,
  hideLabel,
  hideError,
  children,
  onChange,
  ...restProps
}) {
  const { postalCode, setPostalCode, postalCodeValid } = usePostalCode(initialValue, required);
  const inputRef = useRef(null);
  const id = useRef(idProp || uniqueId()); // TODO: replace with useId hook when available
  const errorMessageId = `${id.current}-error`;

  const handleRef = useCallback((el) => {
    inputRef.current = el;
    if (onInputRef) {
      onInputRef(el);
    }
  }, [onInputRef]);

  useEffect(() => {
    if (inputRef.current) {
      if (!postalCodeValid) {
        inputRef.current.setCustomValidity(`Please enter a valid ${translate('zip code') }`);
      } else {
        inputRef.current.setCustomValidity('');
      }
    }
  }, [inputRef, postalCodeValid]);

  const handleOnChange = useCallback((e) => {
    setPostalCode(e.target.value);
    if (onChange) {
      onChange(e);
    }
  }, [setPostalCode, onChange]);

  return (
    <div className='postal-code-input'>
      {!hideLabel && (<label htmlFor={id.current} className={labelClassName}>
        {translate('Zip code')}
        {required && ' (required)'}
      </label>)}
      <div className={wrapperClassName}>
        <input
          type='text'
          autoComplete='postal-code'
          spellCheck={false}
          {...countrySpecificProps()}
          className={classNames('form-control', inputClassName, !postalCodeValid && 'is-invalid')}
          id={id.current}
          name={name}
          ref={handleRef}
          defaultValue={postalCode}
          placeholder={showPlaceholder ? translate('Zip code') : null}
          onChange={handleOnChange}
          required={required}
          autoFocus={autoFocus}
          aria-invalid={!postalCodeValid}
          aria-describedby={errorMessageId}
          {...restProps}
        />
        {children}
      </div>
      {!postalCodeValid && !hideError && <InvalidPostalCodeWarning id={errorMessageId} />}
    </div>
  )
}

PostalCodeInput.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.element,
      PropTypes.arrayOf(PropTypes.element),
    ])),
  ]),
  id: PropTypes.string,
  initialValue: PropTypes.string,
  showPlaceholder: PropTypes.bool,
  inputClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  wrapperClassName: PropTypes.string,
  required: PropTypes.bool,
  name: PropTypes.string,
  autoFocus: PropTypes.bool,
  hideLabel: PropTypes.bool,
  onInputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  hideError: PropTypes.bool,
}

PostalCodeInput.defaultProps = {
  id: null,
  name: null,
  initialValue: '',
  showPlaceholder: false,
  inputClassName: null,
  labelClassName: null,
  wrapperClassName: 'controls',
  required: false,
  autoFocus: false,
  onInputRef: null,
  hideLabel: false,
  hideError: false,
}

export default PostalCodeInput;
